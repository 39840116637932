<template>
  <div class="orderList container">
    <div class="searchbox">
      <el-form inline>
        <el-form-item label="商品关键字">
          <el-input v-model="goods_name"
                    placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="商品编码">
          <el-input v-model="goods_code"
                    placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="商品分类">
          <el-cascader v-model="category"
                       :options="categoryList"
                       :props="{ expandTrigger: 'hover', checkStrictly: true }"
                       placeholder="选择后台分类"
                       clearable></el-cascader>
        </el-form-item>
        <el-button type="primary"
                   @click="search">查询</el-button>
        <el-button @click="reset">重置</el-button>
        <el-button @click="exportcommissionlists"
                   v-if="module_id_array.includes('132')">导出</el-button>
      </el-form>
    </div>
    <div class="searchBox flex between">
      <div>
        <el-button type="primary"
                   @click="goodsShow = true"
                   v-if="module_id_array.includes('128')">添加推广</el-button>
        <el-button @click="cancleCommission(null)"
                   v-if="module_id_array.includes('129')">取消推广</el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; min-width: 1000px"
                :header-cell-style="{ background: '#F5F5F5' }"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="55"> </el-table-column>
        <el-table-column label="商品"
                         width="200">
          <template slot-scope="scope">
            <div class="flex align-center">
              <img :src="scope.row.picture_str"
                   alt=""
                   style="width: 60px; margin-right: 10px" />
              {{ scope.row.goods_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="推广期销量"
                         prop="order_num"
                         width="100"></el-table-column>
        <el-table-column label="售价"
                         width="350">
          <template slot-scope="scope">
            <template v-if="scope.row.item.length == 0">
              <div class="flex">
                <div>原价：￥{{ scope.row.price }}</div>
                <div style="margin-left: 10px"
                     v-if="scope.row.member_price">
                  会员价：￥{{ scope.row.member_price }}
                </div>
                <div style="margin-left: 10px"
                     v-if="scope.row.promote_price">
                  活动价：￥{{ scope.row.promote_price }}
                </div>
              </div>
            </template>
            <template v-if="scope.row.item.length > 0">
              <div class="flex"
                   v-for="(item, index) in scope.row.item"
                   :key="index">
                <div>原价：￥{{ item.price }}</div>
                <div style="margin-left: 10px"
                     v-if="item.member_price">
                  会员价：￥{{ item.member_price }}
                </div>
                <div v-if="item.promote_price"
                     style="margin-left: 10px">
                  活动价：￥{{ item.promote_price }}
                </div>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="商品sku"
                         width="200">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.item"
                 :key="index"
                 style="
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ item.sku_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="推广文案" width="300">
          <template slot-scope="scope">
            <div class="over-heid">
              {{ scope.row.commission_deac }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="提成比例">
          <template slot-scope="scope">
            <template v-if="scope.row.item.length == 0">
              一级{{ scope.row.commission_one_proportion }}%，二级{{
                scope.row.commission_two_proportion
              }}%
            </template>
            <template v-if="scope.row.item.length > 0">
              <div v-for="(item, index) in scope.row.item"
                   :key="index">
                一级{{ item.commission_one_proportion }}%，二级{{
                  item.commission_two_proportion
                }}%
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="预估佣金">
          <template slot-scope="scope">
            <template v-if="scope.row.item.length == 0">
              {{ scope.row.yu_price }}
            </template>
            <template v-if="scope.row.item.length > 0">
              <div v-for="(item, index) in scope.row.item"
                   :key="index">
                {{ item.yu_price }}
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="250">
          <template slot-scope="scope">
            <el-button type="text"
                       v-if="module_id_array.includes('129')"
                       @click="cancleCommission(scope.row.goods_id)">取消推广
            </el-button>
            <el-button type="text"
                       v-if="module_id_array.includes('128')"
                       @click="editCommission(scope.row)">调整提成
            </el-button>
            <el-button type="text"
                       @click="showWriting(scope.row)"
                       v-if="module_id_array.includes('130')">推广文案
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange"
                   @current-change="handleCurrentChange"
                   :current-page="page"
                   :page-sizes="[10, 20, 50, 100]"
                   :page-size="10"
                   layout="total, sizes, prev, pager, next, jumper"
                   :total="total"
                   style="margin: 30px 0 40px 0"></el-pagination>

    <!-- 添加推广 -->
    <el-dialog title="选择商品"
               :visible.sync="goodsShow"
               width="600px"
               class="recommendDialog"
               @closed="closedwin()">
      <div class="bb">
        <div class="felx"
             style="margin-bottom:5px;">
          <el-input v-model="keyword"
                    placeholder="商品名"
                    style="width: 300px; margin-right: 15px"></el-input>
          <el-button type="primary"
                     @click="searchGoodsList">查询</el-button>
        </div>
        <div class="list">
          <div class="box flex bb"
               v-for="(item, index) in goodsList"
               :key="index">
            <el-checkbox v-model="item.checked"></el-checkbox>
            <img :src="item.picture_str || ''" />
            <div class="flex column column-center">
              <div class="d1"
                   style="align-self: start">
                <!-- {{ item.goods_name }} -->
                <span>{{item.goods_name}}</span><span v-if="item.sku_name">[{{item.sku_name}}]</span>
              </div>
              <div class="d2"
                   style="align-self: start">
                <span>原价：{{ item.price }}</span>
                <span v-if="
                    item.promotion_price &&
                    item.promotion_price != 0 &&
                    item.act_type == 1
                  ">活动价：{{ item.promotion_price }}</span>
                <span v-if="
                    item.promotion_price &&
                    item.promotion_price != 0 &&
                    item.act_type == 2
                  ">秒杀价：{{ item.promotion_price }}</span>
                <span v-if="item.member_price && item.member_price != 0">会员价：{{ item.member_price }}</span>
                <span v-if="item.promote_price">活动价：{{ item.promote_price }}</span>

              </div>
            </div>
          </div>
          <p style="margin: 10px 0"
             v-if="goodsList.length == 0">
            暂无搜索商品
          </p>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="goodsShow = false">取 消</el-button>
        <el-button type="primary"
                   @click="checkGood">下一步</el-button>
      </span>
    </el-dialog>

    <!-- 确认比例 -->
    <!-- <el-dialog
      title="设置提成比例"
      :visible.sync="biliShow"
      width="600px"
      class="recommendDialog"
    >
      <div class="content bb">
        <div style="margin-bottom: 10px">
          <el-radio v-model="commission_type" label="1">提成模板</el-radio>
          <span class="gray"
            >模板规则可在提成模板管理中随时修改，任何选择该模板的商品均有效，适用于按品类进行管理</span
          >
        </div>
        <div>
          <el-select
            v-model="commission_tcm_id"
            placeholder="请选择"
            :disabled="commission_type == 2"
          >
            <el-option
              v-for="item in commissionTcmList"
              :key="item.commission_tcm_id"
              :label="item.commission_tcm_title"
              :value="item.commission_tcm_id"
            >
            </el-option>
          </el-select>
        </div>
        <div style="margin: 10px 0">
          <el-radio v-model="commission_type" label="2">固定比例</el-radio>
          <span class="gray"
            >一级为直接下级购买时，二级为直接下级的又一级购买时</span
          >
          <div class="flex align-center" style="margin: 10px 0">
            <div style="width: 100px">一级佣金比例：</div>
            <el-input-number
              v-model="commission_one_proportion"
              :min="0"
              :max="99"
              label="请设置"
              :controls="false"
              :disabled="commission_type == 1"
            ></el-input-number>
            %
          </div>
          <div class="flex align-center" style="margin: 10px 0">
            <div style="width: 100px">二级佣金比例：</div>
            <el-input-number
              v-model="commission_two_proportion"
              :min="0"
              :max="99"
              label="请设置"
              :controls="false"
              :disabled="commission_type == 1"
            ></el-input-number
            >%
          </div>
          <div class="flex align-center" style="margin: 10px 0">
            <div style="width: 100px">自购折扣：</div>
            <el-input-number
              v-model="commission_self_proportion"
              :min="0"
              :max="10"
              label="请设置"
              :controls="false"
              :disabled="commission_type == 1"
            ></el-input-number
            >折
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="biliShow = false">取 消</el-button>
        <el-button type="primary" @click="addCommission">确 定</el-button>
      </span>
    </el-dialog> -->

    <!-- 确认比例 -->
    <el-dialog title="设置提成比例"
               :visible.sync="biliShow"
               width="850px"
               class="recommendDialog"
               @closed="cloBiliShow()">
      <div class="flex align-center">
        <div style="width:70px;">提成设置</div>
        <div>
          <el-radio v-model="commission_choose_type"
                    label="1">统一提成模板</el-radio>
          <el-radio v-if="chooseList.length>1"
                    v-model="commission_choose_type"
                    label="2">单独配置</el-radio>
        </div>
      </div>
      <div class="flex align-center"
           v-if="commission_choose_type==1">
        <div style="width:70px;">统一提成</div>
        <div class="flex align-center">
          <div style="margin: 0 20px 0 0">
            <el-radio v-model="commission_type"
                      label="1">提成模板</el-radio>
            <el-select v-model="commission_tcm_id"
                       placeholder="请选择"
                       :disabled="commission_type == 2">
              <el-option v-for="item in commissionTcmList"
                         :key="item.commission_tcm_id"
                         :label="item.commission_tcm_title"
                         :value="item.commission_tcm_id">
              </el-option>
            </el-select>
          </div>

          <div style="margin: 10px 0;"
               class="flex align-center">
            <div class="flex align-center"
                 style="margin-right: 30px;">
              <el-radio v-model="commission_type"
                        label="2">固定比例</el-radio>
            </div>
            <div>
              <div class="flex align-center"
                   style="margin: 10px 0">
                <div style="width: 100px">一级佣金比例：</div>
                <el-input-number v-model="commission_one_proportion"
                                 :min="0"
                                 :max="99"
                                 label="请设置"
                                 :controls="false"
                                 :disabled="commission_type == 1"></el-input-number>
                %
              </div>
              <div class="flex align-center"
                   style="margin: 10px 0">
                <div style="width: 100px">二级佣金比例：</div>
                <el-input-number v-model="commission_two_proportion"
                                 :min="0"
                                 :max="99"
                                 label="请设置"
                                 :controls="false"
                                 :disabled="commission_type == 1"></el-input-number>%
              </div>
              <div class="flex align-center"
                   style="margin: 10px 0">
                <div style="width: 100px">自购折扣：</div>
                <el-input-number v-model="commission_self_proportion"
                                 :min="0"
                                 :max="10"
                                 label="请设置"
                                 :controls="false"
                                 :disabled="commission_type == 1"></el-input-number>折
              </div>
            </div>
          </div>

        </div>
      </div>
      <div v-if="commission_choose_type==2"
           style="margin-top:10px;">
        <div>单独配置</div>
        <div class="good flex align-center bb"
             v-for="(item, index) in chooseList"
             :key="index">
          <div style="width: 100px"><span>{{item.goods_name}}</span> <span
                  v-if="item.sku_name">[{{item.sku_name}}]</span></div>
          <el-radio v-model="item.commission_type"
                    label="1">提成模板</el-radio>
          <el-select v-model="item.commission_tcm_id"
                     placeholder="请选择"
                     :disabled="item.commission_type == 2">
            <el-option v-for="item in commissionTcmList"
                       :key="item.commission_tcm_id"
                       :label="item.commission_tcm_title"
                       :value="item.commission_tcm_id">
            </el-option>
          </el-select>
          <el-radio v-model="item.commission_type"
                    label="2"
                    style="margin-left: 10px">固定比例</el-radio>
          <div>
            <div class="flex align-center"
                 style="margin: 10px 0">
              <div style="width: 100px">一级佣金比例：</div>
              <el-input-number v-model="item.commission_one_proportion"
                               :min="0"
                               :max="99"
                               label="请设置"
                               :controls="false"
                               :disabled="item.commission_type == 1"></el-input-number>%
            </div>
            <div class="flex align-center"
                 style="margin: 10px 0">
              <div style="width: 100px">二级佣金比例：</div>
              <el-input-number v-model="item.commission_two_proportion"
                               :min="0"
                               :max="99"
                               label="请设置"
                               :controls="false"
                               :disabled="item.commission_type == 1"></el-input-number>%
            </div>
            <div class="flex align-center"
                 style="margin: 10px 0">
              <div style="width: 100px">自购折扣：</div>
              <el-input-number v-model="item.commission_self_proportion"
                               :min="0"
                               :max="10"
                               label="请设置"
                               :controls="false"
                               :disabled="item.commission_type == 1"></el-input-number>折
            </div>
          </div>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="biliShow = false">取 消</el-button>
        <el-button type="primary"
                   @click="addCommission">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 调整提成比例 -->
    <el-dialog title="调整提成比例"
               :visible.sync="editShow"
               width="900px"
               class="recommendDialog">
      <div class="bb"
           style="height: 400px; overflow: auto">
        <div class="good flex align-center bb"
             v-for="(item, index) in editList"
             :key="index">
          <div style="width: 100px"><span>{{ item.name }}</span> <span v-if="item.sku_name">[{{item.sku_name}}]</span>
          </div>
          <el-radio v-model="item.commission_type"
                    label="1">提成模板</el-radio>
          <el-select v-model="item.commission_tcm_id"
                     placeholder="请选择"
                     :disabled="item.commission_type == 2">
            <el-option v-for="item in commissionTcmList"
                       :key="item.commission_tcm_id"
                       :label="item.commission_tcm_title"
                       :value="item.commission_tcm_id">
            </el-option>
          </el-select>
          <el-radio v-model="item.commission_type"
                    label="2"
                    style="margin-left: 10px">固定比例</el-radio>
          <div>
            <div class="flex align-center"
                 style="margin: 10px 0">
              <div style="width: 100px">一级佣金比例：</div>
              <el-input-number v-model="item.commission_one_proportion"
                               :min="0"
                               :max="99"
                               label="请设置"
                               :controls="false"
                               :disabled="item.commission_type == 1"></el-input-number>%
            </div>
            <div class="flex align-center"
                 style="margin: 10px 0">
              <div style="width: 100px">二级佣金比例：</div>
              <el-input-number v-model="item.commission_two_proportion"
                               :min="0"
                               :max="99"
                               label="请设置"
                               :controls="false"
                               :disabled="item.commission_type == 1"></el-input-number>%
            </div>
            <div class="flex align-center"
                 style="margin: 10px 0">
              <div style="width: 100px">自购折扣：</div>
              <el-input-number v-model="item.commission_self_proportion"
                               :min="0"
                               :max="10"
                               label="请设置"
                               :controls="false"
                               :disabled="item.commission_type == 1"></el-input-number>折
            </div>
          </div>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary"
                   @click="changeCommission">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 推广文案 -->
    <el-dialog title="推广文案"
               :visible.sync="writingShow"
               width="800px"
               class="recommendDialog">
      <div class="content bb"
           style="height: 400px; overflow: auto">
        <div class="flex"
             style="margin-bottom: 10px">
          <span class="text-info">商品名称：</span>{{ writingInfo.name }}
        </div>
        <div class="flex align-center"
             style="margin-bottom: 10px">
          <span class="text-info">推广图片：</span>
          <el-upload :show-file-list="false"
                     :http-request="uploadImg"
                     action=""
                     v-if="writingInfo.commission_image.length < 5">
            <el-button type="text">上传本地图片</el-button>
          </el-upload>
        </div>
        <draggable class="imgList flex"
                   v-model="writingInfo.commission_image"
                   style="padding-left:71px;width:100%;flex-wrap: wrap;">
          <div class="box"
               v-for="item in writingInfo.commission_image"
               :key="item">
            <el-image :src="item"
                      style="width: 150px; height: 150px; text-align: center"
                      fit="fill"></el-image>
            <el-upload :show-file-list="false"
                       action=""
                       :http-request="
                (parms) => {
                  uploadImg(parms, item);
                }
              ">
              <div class="shadow column-center column">
                <div>
                  <!-- <i class="el-icon-search" @click.stop="showImg(item)"></i> -->
                  <i class="el-icon-delete"
                     @click.stop="delImg(item)"></i>
                </div>
              </div>
            </el-upload>
          </div>
        </draggable>
        <div class="flex"
             style="margin-bottom: 10px">
          <span class="text-info">推广文案：</span>
          <el-input type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    maxlength="200"
                    style="width: 600px"
                    v-model="writingInfo.content">
          </el-input>
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="writingShow = false">取 消</el-button>
        <el-button type="primary"
                   @click="saveCommissionCopywriting">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer"
                     :on-close="
        () => {
          showViewer = false;
        }
      "
                     :url-list="imgList" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { uploadImg, getImgUrl } from "../../service/common";
import commonUtil from '../../utils/commonUtil.js';
export default {
  name: "orderList",
  data() {
    return {
      total: 0,
      page: 1,
      pageNumber: 10,
      goods_name: "",
      goods_code: "",
      categoryList: [],
      category: [],
      tableData: [],
      multipleSelection: [],
      goodsShow: false,
      keyword: "",
      goodsList: [], //推广商品列表
      chooseList: [], //选中的推广商品
      biliShow: false,
      commission_choose_type: '1',
      commission_type: "1", //提成方式
      commissionTcmList: [], //模板列表
      commission_tcm_id: "", //选择的模板id
      commission_one_proportion: undefined,
      commission_two_proportion: undefined,
      commission_self_proportion: undefined,
      module_id_array: window.utils.storage.getter("module_id_array") || [],
      editShow: false, //调整提成弹窗
      editList: [],
      writingShow: false,
      writingInfo: {
        goods_id: "",
        content: "",
        commission_image: [],
      }, //文案对象
      showViewer: false,
      imgList: [], //预览图片
    };
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
    draggable,
  },
  created() {
    this.getCategoryList();
    this.getCommissionTcmList();
  },
  activated() {
    this.getList();
  },
  methods: {
    getList() {
      this.tableData = [];
      let data = {
        page: this.page,
        pageNumber: this.pageNumber,
        goods_name: this.goods_name,
        goods_code: this.goods_code,
        category_id_1: "",
        category_id_2: "",
      };
      if (this.category[0]) {
        data.category_id_1 = this.category[0];
      }
      if (this.category[1]) {
        data.category_id_2 = this.category[1];
      }
      this.axios
        .post("/store/commission/getCommissionList", data)
        .then((res) => {
          this.total = res.data.total;
          let list = res.data.list;
          list.forEach((ele) => { });
          this.tableData = list;
        });
    },

    reset() {
      commonUtil.lostFocus(event)
      this.page = 1;
      this.getList();
    },
    search() {
      commonUtil.lostFocus(event)
      this.page = 1;
      this.getList();
    },
    //平台分类列表
    getCategoryList() {
      this.axios.post("/store/Shopgoods/getCategoryList").then((res) => {
        let list = res.data.list;
        let arr = [];
        list.forEach((ele) => {
          if (ele.child.length == 0) {
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
            });
          } else {
            let childArr = [];
            ele.child.forEach((e) => {
              childArr.push({
                value: e.category_id,
                label: e.category_name,
              });
            });
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
              children: childArr,
            });
          }
        });
        this.categoryList = arr;
      });
    },
    handleSizeChange(pageNumber) {
      this.pageNumber = pageNumber;
      this.getList();
    },
    handleCurrentChange(size) {
      this.page = size;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 关闭添加dialog后重置数据
    closedwin() {
      this.keyword = ''
      this.goodsList = []
    },
    cloBiliShow() {
      this.commission_choose_type = '1'
      this.commission_type = "1" //提成方式
      this.commission_tcm_id = "" //选择的模板id
      this.commission_one_proportion = undefined
      this.commission_two_proportion = undefined
      this.commission_self_proportion = undefined
    },
    //取消推广
    cancleCommission(goods_id) {
      commonUtil.lostFocus(event)
      if (!goods_id && this.multipleSelection.length == 0) {
        this.$message.error("请选择商品！");
        return;
      }
      this.$confirm("确定下架吗？", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let arr = this.multipleSelection.map((ele) => {
          return ele.goods_id;
        });
        let data = {
          goods_list: goods_id || arr.toString(),
        };
        this.axios
          .post("/store/commission/cancleCommission", data)
          .then((res) => {
            this.getList();
            this.$message.success("下架成功");
          });
      });
    },
    //搜索商品列表
    searchGoodsList() {
      commonUtil.lostFocus(event)
      if (!this.keyword) {
        this.$message.error("请输入商品信息！");
        return false;
      }
      let data = {
        keywords: this.keyword,
        pageNumber: 20,
        page: 1,
      };
      this.axios
        .post("/store/commission/ShopGoodsLists", data)
        .then((res) => {
          let list = res.data.list;
          list.forEach((ele) => {
            ele.checked = false;
          });
          this.goodsList = list;
        });
    },
    //选择商品,到下一步
    checkGood() {
      commonUtil.lostFocus(event)
      let arr = this.goodsList.filter((ele) => {
        return ele.checked == true;
      });
      if (!arr || arr.length == 0) {
        this.$message.error("请选择商品");
        return;
      }
      this.chooseList = arr
      console.log('0123', this.chooseList)
      this.keyword = "";
      this.goodsShow = false;
      this.biliShow = true;
    },
    //确认添加分销商品
    addCommission() {
      commonUtil.lostFocus(event)
      if (this.commission_choose_type == 1) {
        if (this.commission_type == 1 && !this.commission_tcm_id) {
          this.$message.error("请选择模板或填写比例");
          return;
        }
        if (this.commission_type == 2 && !(this.commission_one_proportion &&
          this.commission_two_proportion &&
          this.commission_self_proportion)) {
          this.$message.error("请选择模板或填写比例");
          return;
        }
      } else if (this.commission_choose_type == 2) {
        const istrue = this.chooseList.map((el) => {
          if (el.commission_type == 1 && !el.commission_tcm_id) {
            return undefined
          } else if (el.commission_type == 1 && el.commission_tcm_id) {
            return 1
          }
          if (el.commission_type == 2 && !(el.commission_one_proportion &&
            el.commission_two_proportion &&
            el.commission_self_proportion)) {
            return undefined
          } else if (el.commission_type == 2 && (el.commission_one_proportion &&
            el.commission_two_proportion &&
            el.commission_self_proportion)) {
            return 1
          }
          if (el.commission_type == undefined) {
            return el.commission_type
          }
        })
        console.log('istrue', istrue)
        if (istrue.includes(undefined)) {
          this.$message.error("请选择模板或填写比例");
          return;
        }
      }
      // 老方法
      // let goods_list = [];
      // const arr = this.goodsList.filter((ele) => {
      //   return ele.checked == true;
      // });
      // arr.forEach((ele) => {
      //   if (this.commission_type == 1) {
      //     goods_list.push({
      //       goods_id: ele.goods_id || "",
      //       sku_id: ele.sku_id || 0,
      //       commission_type: 1,
      //       commission_tcm_id: this.commission_tcm_id,
      //       commission_one_proportion: "",
      //       commission_two_proportion: "",
      //       commission_self_proportion: "",
      //     });
      //   } else if (this.commission_type == 2) {
      //     goods_list.push({
      //       goods_id: ele.goods_id || "",
      //       sku_id: ele.sku_id || 0,
      //       commission_type: 2,
      //       commission_tcm_id: "",
      //       commission_one_proportion: this.commission_one_proportion,
      //       commission_two_proportion: this.commission_two_proportion,
      //       commission_self_proportion: this.commission_self_proportion,
      //     });
      //   }
      // });

      // 新方法
      let goods_list = [];
      // 当选中统一模板
      if (this.commission_choose_type == 1) {
        this.chooseList.forEach((ele) => {
          if (this.commission_type == 1) {
            goods_list.push({
              goods_id: ele.goods_id || "",
              sku_id: ele.sku_id || 0,
              commission_type: 1,
              commission_tcm_id: this.commission_tcm_id,
              commission_one_proportion: "",
              commission_two_proportion: "",
              commission_self_proportion: "",
            });
          } else if (this.commission_type == 2) {
            goods_list.push({
              goods_id: ele.goods_id || "",
              sku_id: ele.sku_id || 0,
              commission_type: 2,
              commission_tcm_id: "",
              commission_one_proportion: this.commission_one_proportion,
              commission_two_proportion: this.commission_two_proportion,
              commission_self_proportion: this.commission_self_proportion,
            });
          }
        })
      } else if (this.commission_choose_type == 2) { //选中单一设置

        this.chooseList.forEach((ele) => {
          if (ele.commission_type == 1) {
            goods_list.push({
              goods_id: ele.goods_id || "",
              sku_id: ele.sku_id || 0,
              commission_type: 1,
              commission_tcm_id: ele.commission_tcm_id,
              commission_one_proportion: "",
              commission_two_proportion: "",
              commission_self_proportion: "",
            });
          } else if (ele.commission_type == 2) {
            goods_list.push({
              goods_id: ele.goods_id || "",
              sku_id: ele.sku_id || 0,
              commission_type: 2,
              commission_tcm_id: "",
              commission_one_proportion: ele.commission_one_proportion,
              commission_two_proportion: ele.commission_two_proportion,
              commission_self_proportion: ele.commission_self_proportion,
            });
          }
        })
      }

      let data = {
        goods_list,
      };
      console.log('goods_list', data)
      this.axios.post("/store/commission/addCommission", data).then((res) => {
        this.biliShow = false;
        this.goodsList = [];
        this.$message.success("添加成功");
        this.getList();
      });
    },
    //修改分销商品
    editCommission(goods) {
      this.editList = [];
      if (goods.item.length == 0) {
        this.editList = [
          {
            name: goods.goods_name,
            goods_id: goods.goods_id,
            sku_id: goods.sku_id || 0,
            commission_type: goods.commission_type.toString(),
            commission_tcm_id: goods.commission_tcm_id || "",
            commission_one_proportion:
              goods.commission_one_proportion || undefined,
            commission_two_proportion:
              goods.commission_two_proportion || undefined,
            commission_self_proportion:
              goods.commission_self_proportion || undefined,
          },
        ];
      } else if (goods.item.length > 0) {
        goods.item.forEach((ele) => {
          this.editList.push({
            name: goods.goods_name,
            sku_name: ele.sku_name,
            goods_id: ele.goods_id,
            sku_id: ele.sku_id,
            commission_type: ele.commission_type.toString(),
            commission_tcm_id: ele.commission_tcm_id || "",
            commission_one_proportion:
              ele.commission_one_proportion || undefined,
            commission_two_proportion:
              ele.commission_two_proportion || undefined,
            commission_self_proportion:
              ele.commission_self_proportion || undefined,
          });
        });
      }
      this.editShow = true;
    },
    //确认编辑
    changeCommission() {
      let data = { goods_list: this.editList };
      this.axios.post("/store/commission/addCommission", data).then((res) => {
        this.editShow = false;
        this.editList = [];
        this.$message.success("修改成功");
        this.getList();
      });
    },
    getCommissionTcmList() {
      let data = {
        status: 0,
        page: 1,
        pageNumber: 30,
      }
      this.axios.post("/store/commission/getDropCommissionTcmList", data).then((res) => {
        let list = res.data.list;
        this.commissionTcmList = list;
      });
    },
    //文案对象
    showWriting(item) {
      this.writingInfo = {
        goods_id: item.goods_id,
        name: item.goods_name,
        content: item.commission_deac || "",
        commission_image: item.commission_image_str || [],
      };
      this.writingShow = true;
    },
    //修改文案
    saveCommissionCopywriting() {
      commonUtil.lostFocus(event)
      if (
        !this.writingInfo.content ||
        this.writingInfo.commission_image.length == 0
      ) {
        this.$message.error("请填写必要项");
        return;
      }
      let data = {
        goods_id: this.writingInfo.goods_id,
        content: this.writingInfo.content,
        commission_image: [],
      };
      data.commission_image = this.writingInfo.commission_image.map((ele) => {
        return getImgUrl(ele);
      });
      console.log(data);
      this.axios
        .post("/store/commission/saveCommissionCopywriting", data)
        .then((res) => {
          this.getList();
          this.$message.success("更新成功");
          this.writingShow = false;
        });
    },
    //上传图片
    uploadImg(params, item) {
      let file = params.file;
      uploadImg(file)
        .then((res) => {
          if (!item) {
            this.writingInfo.commission_image.push(res.data.path);
          } else if (item) {
            let index = this.writingInfo.commission_image.findIndex((ele) => {
              return ele == item;
            });
            this.writingInfo.commission_image[index] = res.data.path;
            this.$forceUpdate();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查看图片
    showImg(item) {
      this.imgList = [item];
      this.showViewer = true;
    },
    //删除图片
    delImg(item, type) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let list = this.writingInfo.commission_image;
          list.splice(
            list.findIndex((ele) => item == ele),
            1
          );
        })
        .catch(() => { });
    },
    //导出
    exportcommissionlists() {
      let data = {
        goods_name: this.goods_name,
        goods_code: this.goods_code,
        category_id_1: "",
        category_id_2: "",
      };
      if (this.category[0]) {
        data.category_id_1 = this.category[0];
      }
      if (this.category[1]) {
        data.category_id_2 = this.category[1];
      }
      this.axios
        .post("/store/commission/exportcommissionlists", data)
        .then((res) => {
          let host =
            document.location.protocol +
            "//" +
            window.location.host +
            res.data.url;

          window.open(host);
        });
    },
  },
};
</script>

<style lang='scss' scoped>
.over-heid{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>
<style lang='scss'>
.orderList {
  .el-range-editor {
    width: 250px;
  }
}
.recommendDialog {
  .content {
    padding: 15px;
  }
  .list {
    height: 500px;
    overflow: auto;
    .box {
      margin: 15px 0;
      padding: 10px;
      border-radius: 10px;
      border: 1px solid #cccccc;
      .el-checkbox {
        align-self: center;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 5px;
        margin-right: 10px;
        margin-left: 10px;
      }
      .d1 {
        margin-bottom: 5px;
        line-height: 25px;
        text-align: left;
        span {
          margin-right: 10px;
        }
      }
      .d2 {
        margin-bottom: 5px;
        line-height: 25px;
        text-align: left;
        span {
          color: #a0918c;
          margin-right: 10px;
        }
      }
    }
  }
  .text-info {
    display: inline-block;
    width: 71px;
  }
  .good {
    border: 1px solid #cccccc;
    padding: 10px;
  }
  .good + .good {
    border-top: none;
  }
  .imgList {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    .box {
      position: relative;
      margin-right: 10px;
      margin-bottom: 10px;
      &:hover .shadow {
        display: flex;
      }
      .shadow {
        position: absolute;
        left: 0;
        top: 0;
        width: 150px;
        height: 150px;
        background-color: rgba(0, 0, 0, 0.6);
        display: none;
      }
      i + i {
        margin-left: 30px;
        color: #d44747;
      }
      i {
        color: #d44747;
        //   color: #ffffff;
        font-size: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>