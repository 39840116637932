import { render, staticRenderFns } from "./MarketList.vue?vue&type=template&id=306d5a2b&scoped=true&"
import script from "./MarketList.vue?vue&type=script&lang=js&"
export * from "./MarketList.vue?vue&type=script&lang=js&"
import style0 from "./MarketList.vue?vue&type=style&index=0&id=306d5a2b&lang=scss&scoped=true&"
import style1 from "./MarketList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "306d5a2b",
  null
  
)

export default component.exports